<template>
  <p>This content is hidden.</p>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
</style>
